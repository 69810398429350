import React, { useState, forwardRef, useEffect,useImperativeHandle  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
 import {useNavigate, useParams} from "react-router-dom";

const InviteCustomer = forwardRef((props, ref) => {
   const navigate = useNavigate();
      const [inviteModal , setInviteModal] = useState(false);
    useImperativeHandle(ref, () => ({
        showInviteModal() {
            setInviteModal(true)
        }
    
      }))      ;
      
      const [errors, setErrors] = useState('')
      const userdata1 = localStorage.getItem('USER_data');
      const userData = JSON.parse(userdata1);
        const userid = userData.id;

    const [hallData, setHallData] = useState({
      HallName: '',
      HallAddress: '',
      HallContactNumber: '',
      ItemPrice: '',
      UserID: userid,
      PreName: '',
    });
    console.log("foodItem",hallData);

 

   const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHallData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async(e)=>{
    e.preventDefault();
    { if (!hallData.HallContactNumber.match(/^(218)?09[123456][0-9]{7}$/)  ) {
   
setErrors('رقم الهاتف  : 00 00 000 091 او 00 00 000 092')
console.log(errors)
   }
else{
    await axios.post('https://suhba.server.com.ly/back/api/wedding_halls', hallData)
     // setNotification('User was successfully created')
     // navigate('/users')
     .catch(function (error) {
        if (error.response) {
           console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
     
      setInviteModal(false);
      props.parentFunction()
      setHallData('');
      setErrors('')

  }}};
      const showInviteModal = () => {   
        
        setInviteModal(false)
        navigate('/WeddingHall')

    }
     

    return(
        <>
            <Modal className="modal fade" id="exampleModal1" show={inviteModal}  centered>                
                <div className="modal-content">
                <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">بيانات صالة جديدة</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>setInviteModal(false)}></button>
                    </div>
                          <form onSubmit={handleSubmit}>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-xl-12">
                      

                            <div className="row">
                            <div className="col-xl-12">
                        {errors? <div className=' alert alert-danger'>{errors}</div> : ""}
                        </div>
                                    <div className="col-xl-6 p-0">
                                        <label className="form-label mt-3  ">  الاسم الحالي<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type="text"className="form-control" name="HallName" 
                                             pattern="[a-zA-Z\u0600-\u06FF\s]+"
                                        title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                                        required value={hallData.HallName} onChange={handleInputChange} />

                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3">الاسم السابق<span className="text-danger">*</span></label>
                                        <div className="input-group">
 
                                        <input type="text" className="form-control" name="PreName" 
                                        value={hallData.PreName} onChange={handleInputChange}
                                        pattern="[a-zA-Z\u0600-\u06FF\s]+"
                                        title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                                        required
                                        />

                                         </div>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                <label className="form-label mt-3">العنوان<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="HallAddress"
                                 pattern="[a-zA-Z\u0600-\u06FF\s]+"
                                 title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                                 required
                                 value={hallData.HallAddress} onChange={handleInputChange} />
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 p-0">
                                        <label className="form-label mt-3">رقم الهاتف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="text" className="form-control" name="HallContactNumber" value={hallData.HallContactNumber} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3">سعر التوصيل<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="text" className="form-control" 
        pattern="\d+(\.\d{1,2})?"
                                                        title="الرجاء إدخال ارقام فقط"
                                        required
                                         name="ItemPrice" value={hallData.ItemPrice} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                </div>
                                <input type="text" hidden="true" name="UserID" value={userid} onChange={handleInputChange} />

                            </div>
                        </div>                                
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" >
                       
                        حفظ</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>showInviteModal()}>إلغاء</button>

                    </div> 
                     </form>
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;

