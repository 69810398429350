import React, { useState, forwardRef, useEffect,useImperativeHandle  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
 import {useNavigate, useParams} from "react-router-dom";

const InviteCustomer = forwardRef((props, ref) => {
   const navigate = useNavigate();
    const [inviteModal , setInviteModal] = useState(null);
      const showInviteModal = () => {   
         props.show(false)   
        setCustomers('')
        }
        const [city, setCity] = useState([])

         //------------------------
         const [customers, setCustomers] = useState({
            CustomerID:'',
            CustomerName:'',
            Notes:'',
            AreaID:'',
            PhoneNumber:'',
            PhoneNumber1:'',
            PhoneNumber2:'',
          });
         useEffect(() => {
            getcities();

            setCustomers(props.data)
             
            }, [props.data, setCustomers]);
            const [errors, setErrors] = useState('')

 //--------------------------------------------------
 const getcities = () => {
    axios.get('https://suhba.server.com.ly/back/api/areas')
     .then(({ data }) => {
      setCity(data)
     })}
 const onSubmit = ev => {
    ev.preventDefault()
    setErrors('')  

    if (!customers.PhoneNumber || !customers.PhoneNumber.match(/^(218)?09[123456][0-9]{7}$/)
      || (customers.PhoneNumber1 && !customers.PhoneNumber1.match(/^(218)?09[123456][0-9]{7}$/))
      || (customers.PhoneNumber2 && !customers.PhoneNumber2.match(/^(218)?09[123456][0-9]{7}$/))) {
   
setErrors('رقم الهاتف  : 00 00 000 091 او 00 00 000 092')
console.log(errors)
   }
else{
 
      axios.put(`https://suhba.server.com.ly/back/api/customers/${customers.CustomerID}`, customers)
        .then(() => {
            setCustomers('')
            props.parentFunction()
      setErrors('')  

            props.show(false) 
        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
         

  }}
console.log('uder',customers)
useEffect(() => {
    const handleEnterKey = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const form = e.target.form;
        const fields = Array.from(form.elements).filter(el => (
          (el.tagName === 'INPUT' && !el.disabled) ||
          (el.tagName === 'TEXTAREA' && !el.disabled) ||
          (el.tagName === 'SELECT' && !el.disabled)
        ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
        const nextFieldIndex = currentFieldIndex + 1;
        if (nextFieldIndex < fields.length) {
          fields[nextFieldIndex].focus();
        }
      }
    };
    
    document.addEventListener('keydown', handleEnterKey);
    
    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, []);
   
 //---------------------------------------------
    return(
        <>
         <div>

         </div>
             <Modal className="modal fade" id="exampleModal1"   show={props.inviteModal}  centered> 
            
        
                <div className="modal-content">
                <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">
                        تعديل بيانات الربون</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>showInviteModal()}></button>
                    </div>
                          <form onSubmit={onSubmit}>
                    <div className="modal-body">
                        <div className="row">
                        <div className="col-xl-12">
                        {errors? <div className=' alert alert-danger'>{errors}</div> : ""}
                        </div>   </div>

                            <div className="row">
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3 ">الاسم <span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <input type="text"className="form-control" name="CustomerName"  defaultValue={customers.CustomerName} 
                                            onChange={ev => setCustomers({...customers, CustomerName: ev.target.value})} 
                                            pattern="[a-zA-Z\u0600-\u06FF\s]+"
                                            title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                                            required  />

                                        </div>
                                    </div>
                                  
                             
                                <div className="col-xl-6">
                                <label className="form-label mt-3">المدينة<span className="text-danger">*</span></label>
                             
                                <select className="default-select form-control"
                              name="AreaID"
                                 value={customers.AreaID}
                            onChange={(ev) => setCustomers({ ...customers, AreaID: ev.target.value })}
>
                           <option value="" data-display="Select">
                             {customers.AreaID}
                                             </option>
                        {city.length > 0 &&
                    city.map((row) => (
                     <option key={row.AreaID} value={row.AreaID}>
                   {row.AreaName}
               </option>
              ))}
               </select>
                      </div>        
                                
                                
                                
                                
                                
                                
                                <div className="col-xl-6">
                                <label className="form-label mt-3">العنوان<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="HallAddress" defaultValue={customers.Notes} 
                                onChange={ev => setCustomers({...customers, Notes: ev.target.value})}
                                
                                />
                                </div>
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3">رقم الهاتف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="text" className="form-control" name="PhoneNumber" defaultValue={customers.PhoneNumber}
                                        onChange={ev => setCustomers({...customers, PhoneNumber: ev.target.value})}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3">1 رقم الهاتف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="text" className="form-control" name="PhoneNumber1" defaultValue={customers.PhoneNumber1}
                                        onChange={ev => setCustomers({...customers, PhoneNumber1: ev.target.value})}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <label className="form-label mt-3">2 رقم الهاتف<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                        <input type="text" className="form-control"  name="PhoneNumber2" defaultValue={customers.PhoneNumber2} 
                                        onChange={ev => setCustomers({...customers, PhoneNumber2: ev.target.value})}
                                        />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                    <label className="form-label  mt-3">ملاحظة</label>
                    <textarea type="text"  className="form-control" name="Description" 
                      defaultValue={customers.Description} 
                      onChange={ev => setCustomers({...customers, Description: ev.target.value})}
 
                                         />
                </div>
                                </div>
                               
                         
                        </div>                                
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" >
                       
                        حفظ التعديل  </button>
                        <button type="button" className="btn btn-danger light" onClick={()=>showInviteModal()}>إلغاء</button>

                    </div> 
                     </form>
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;