

import React, { useState, forwardRef, useImperativeHandle  ,useEffect} from 'react';
import {Modal} from 'react-bootstrap';
 import { Dropdown } from 'react-bootstrap';
 import axios from "axios";
  import { useNavigate ,useParams} from "react-router-dom";
  import swal from 'sweetalert';
const InviteCustomer = forwardRef((props, ref) => {
    const [inviteModal , setInviteModal] = useState(false);
    const [Name,setName] = useState()
    const [QuantityInStock,setQuantityInStock] = useState('')
    const [ItemPrice,setItemPrice]= useState('')
   // const [AreaName,setAreaName] = useState('')

    


  //  const { AreaID } = useParams();
  const navigate = useNavigate();

  const showInviteModal = () => {   
     
    props.show(false)  
    }
      const [foodIteming, setFoodIteming] = useState([])
     

  useEffect(() => {

    setFoodIteming(props.data)
       
      }, [props.data, setFoodIteming]);
//console.log('prop',props.data[0].Name)
//console.log('prop',foodIteming[0].Name)

  /*const getfooditeming = (id) => {
    console.log('name',id)
    axios.get(`https://suhba.server.com.ly/back/api/getT/${id}`)
     .then(({ data }) => {
      setFoodIteming(data)
      console.log('id',Name)
     }) .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });}*/
  return(
    <> 

<Modal className="modal fade" id="exampleModal1" show={props.inviteModal1} centered>                
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1"> مكونات الصنف</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>showInviteModal()}></button>
                    </div>{
    foodIteming.length > 0 && (
                    <div className='row'>
                    <div className="col-xl-5 m-3">
                                        <label className="form-label ps-2">اسم الصنف:</label>
                                        <label className="form-label ">{foodIteming[0].Name}</label>

                                    </div>
                                    <div className="col-xl-5 m-3 ">
                                        <label className="form-label ps-2 ">لعدد:</label>
                                        <label className="form-label ">{foodIteming[0].ForHowMany}</label>

                                    </div>
                                

                    </div>
    )}
<div className="table-responsive active-projects task-table my-2 mx-2" style={{  height: "400px" }}>
<table className="table">
<thead>
<tr className="center" >
    <th scope="col">اسم المكون</th>
    <th scope="col">الكمية</th>
    <th scope="col">الوحدة</th>

 </tr>
</thead>
<tbody>
{
    foodIteming.length > 0 && (
      foodIteming.map((row,key)=>(
            <tr key={key}> 
                
                <td 

>
{row.IngredientName}

                    
                    
                    
                     </td>
                    
                   
                                <td 

>
{row.Quantity}

                    
                    
                    
                     </td>   
                     <td 
>
{row.IngCatName}

                    
                    
                    
                     </td>    
            </tr> 
        ))
    )
}

</tbody>
</table>
        </div>
        </div>




                           
                                                  
                  
                   
              
</Modal>
</>
)
})
export default InviteCustomer;