
import React, { useState, forwardRef, useEffect,useImperativeHandle  } from 'react';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from "axios";
 import {useNavigate, useParams} from "react-router-dom";

const InviteCustomer = forwardRef((props, ref) => {
   const navigate = useNavigate();
   const [inviteModal , setInviteModal] = useState(false);
   useImperativeHandle(ref, () => ({
       showInviteModal() {
           setInviteModal(true)
       }
   
     }))      ;
     const [city, setCity] = useState([])
     const [errors, setErrors] = useState('')
     const userdata1 = localStorage.getItem('USER_data');
     const userData = JSON.parse(userdata1);
       const userid = userData.id;
         //------------------------
         const [customers, setCustomers] = useState({
            CustomerID:'',
            CustomerName:'',
            Notes:'',
            AreaID:'',
            PhoneNumber:'',
            PhoneNumber1:'',
            PhoneNumber2:'',
          UserID: userid,
          }); console.log(customers)
          useEffect(() => {
            getcities();
           
          }, []);              
          const [reed, setreed] = useState(false)

          //----------------------------------------------------
         // const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
         // const userPermissions = JSON.parse(userPermissionsJSON);
          // Function to search for permissions for a specific page
        //  function getActionsForPage(pageName) {
            // Find the permissions object for the given page
        //    const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
        //  console.log('actionsForPage',pagePermissions); 
       //   const hasReadAction = pagePermissions.action.includes('read');
      //    if (hasReadAction) {
      //      setreed(true)
      //    }
            // If permissions for the page are found, return the actions
        //    if (pagePermissions) {
         //       return pagePermissions.action;
         //   } else {
          // navigate('/customers')
          //
           ///     return [];
           // }
         // }///
          
          // Usage
        //  const pageName = 'Customers';
        //  const actionsForPage = getActionsForPage(pageName);  
 //--------------------------------------------------
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
   
    setCustomers((prevData) => ({
     ...prevData,
      [name]: value,
    }));
  };   
  
  // Function to focus on the next input field

  
 const onSubmit = async (ev) => {
    ev.preventDefault()
     {if (!customers.PhoneNumber || !customers.PhoneNumber.match(/^(218)?09[123456][0-9]{7}$/)
      || (customers.PhoneNumber1 && !customers.PhoneNumber1.match(/^(218)?09[123456][0-9]{7}$/))
      || (customers.PhoneNumber2 && !customers.PhoneNumber2.match(/^(218)?09[123456][0-9]{7}$/))) {
    
setErrors('رقم الهاتف  : 00 00 000 091 او 00 00 000 092')
console.log(errors)
    }
else{
  
   
 

  await axios.post('https://suhba.server.com.ly/back/api/customers', customers).then(({ data }) => {
    
  const queryString = new URLSearchParams({
    id: data.CustomerID.toString(),
    cname:data.CustomerName,
    //total:total,

  }).toString();
  navigate(`/Orders?${queryString}`);
          props.parentFunction() 

        })
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
               //setInviteModal(false);

      //navigate('/Customers')

      //setCustomers('')
    //  setErrors('')
    }
  }}
  const getcities = () => {
    axios.get('https://suhba.server.com.ly/back/api/areas')
     .then(({ data }) => {
      setCity(data)
     })}
 //-------------------
 useEffect(() => {
  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const form = e.target.form;
      const fields = Array.from(form.elements).filter(el => (
        (el.tagName === 'INPUT' && !el.disabled) ||
        (el.tagName === 'TEXTAREA' && !el.disabled) ||
        (el.tagName === 'SELECT' && !el.disabled)
      ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
      const nextFieldIndex = currentFieldIndex + 1;
      if (nextFieldIndex < fields.length) {
        fields[nextFieldIndex].focus();
      }
    }
  };
  
  document.addEventListener('keydown', handleEnterKey);
  
  return () => {
    document.removeEventListener('keydown', handleEnterKey);
  };
}, []);
 
 //--------------------------
    return(
        <>
         <div>

         </div>
             <Modal className="modal fade" id="exampleModal1"   show={inviteModal}  centered> 
            
        
                <div className="modal-content">
                <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">بيانات الزبون</h1>
                        <button type="button" className="btn-close " style={{marginLeft: 0 }}  padding="" onClick={()=>setInviteModal(false)}></button>
                    </div>
                          <form onSubmit={onSubmit}>
                    <div className="modal-body">
                        <div className="row">
                        <div className="col-xl-12">
                        {errors? <div className=' alert alert-danger'>{errors}</div> : ""}
                        </div>
                        <div className="col-xl-6">

                <label className="form-label mt-3">الاسم<span className="text-danger">*</span></label>
                <input className="form-control"
          type="text"
          name="CustomerName"
           pattern="[a-zA-Z\u0600-\u06FF\s]+"
          title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
          required      
          defaultValue={customers.CustomerName} onChange={handleInputChange1}                      /></div>

                 <div className="col-xl-6">
                <label className="form-label mt-3">المدينة<span className="text-danger">*</span></label>
             
                <select        className="default-select form-control" required name="AreaID" defaultValue={customers.AreaID} onChange={handleInputChange1}>
                 <option  data-display="Select">اختر من القائمة</option>
                 {
                   city.map((row)=>(
                  <option key={row.AreaID} value={row.AreaID}>
                            {row.AreaName}
                    </option>
                     ))}    
                 </select> 
                   </div>
                   
                 <div className="col-xl-6">
                    <label className="form-label  mt-3 ">رقم الهاتف<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" name="PhoneNumber" defaultValue={customers.PhoneNumber} 
                        required                      // Focus on next field if Enter is pressed
                       onChange={handleInputChange1}/>
                </div> 	
               	
                <div className="col-xl-6">
                    <label className="form-label  mt-3 ">رقم الهاتف 1 </label>
                    <input type="text"  className="form-control" name="PhoneNumber1" 
                     defaultValue={customers.PhoneNumber1} onChange={handleInputChange1}     
                      />
                </div> 
                <div className="col-xl-6">
                    <label className="form-label  mt-3"  >رقم الهاتف 2</label>
                    <input type="text"  className="form-control" name="PhoneNumber2" 
                      defaultValue={customers.PhoneNumber2} onChange={handleInputChange1}                       />
                </div>
                <div className="col-xl-6">
                <label className="form-label  mt-3 "> العنوان<span className="text-danger">*</span></label>
          <textarea className="form-control "  rows="3"
  name="Notes" 
 pattern="[a-zA-Z\u0600-\u06FF\s]+"
 title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
   
 defaultValue={customers.Notes} onChange={handleInputChange1} 
                                        ></textarea>
                  
                </div> 
                <div className="col-xl-12">
                    <label className="form-label  mt-3">ملاحظة</label>
                    
                    <textarea type="text"  className="form-control" name="Description" 
                      defaultValue={customers.Description} onChange={handleInputChange1}    
                               />
                </div>
                         </div>                                
                    </div>
                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary" >
                       
                        حفظ</button>
                        <button type="button" className="btn btn-danger light" onClick={()=>setInviteModal(false)}>إلغاء</button>

                    </div> 
                     </form>
                </div>               
            </Modal>
        </>
    )
})
export default InviteCustomer;
