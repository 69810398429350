//-------احصائيات
 import { SVGICON } from '../jsx/constant/theme';
 import Sts from './Statistices';
import InviteCustomer from '../jsx/constant/AddTabak';
import InserdeModelUpdate from '../jsx/constant/EditTabak';
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap';

import swal from 'sweetalert';

//----------------
import React, { useEffect, useState ,useRef} from "react";
import { Link } from "react-router-dom";
  import axios from "axios";


const Atbak = () => {
//-------احصائيات
const Update= useRef()

const navigate = useNavigate();

const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');
const userPermissions = JSON.parse(userPermissionsJSON);
function getActionsForPage(pageName) {
  const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
console.log('actionsForPage',pagePermissions); 
 if (pagePermissions) {
     return pagePermissions.action;
 } else {
navigate('/dashboard_1')
    return [];
 }
}
const pageName = 'Atbak';
const actionsForPage = getActionsForPage(pageName);
//-------------
//---AtbakID';
  //  protected $fillable = [
   //   'TabakName' ,	'OriginalPrice' ,	'TabakPrice' 	,'QuantityInStock' 	
    //   ,'Quantity' ,	'TabakDate' ,	'ItemID' 	,'UserID'];
const invite = useRef();
const [data, setData] = useState([])

const [errors, setErrors] = useState('')
const [AtbakID, setAtbakID] = useState('')
const [price1, setprice1] = useState('')

const listClickHandler1 = (itemid,price) => {
  data.map((item) => {
    if (itemid == item.AtbakID) {
        if(item.QuantityInStock < 1)
          {swal({
            title: " لا توجد اطباق من هدا الصنف ",
            icon: "warning",
            timer: 2000, // Time in milliseconds (2 seconds in this example)
            buttons: false, // Hide the close button
            content: {
              element: "p",
              attributes: {
                 style: "padding-bottom: 10px;" // Add padding here (adjust as needed)
              }}
          });

          }else{
            setAtbakID(itemid)
            setprice1(price)
     
     setpopupModal(true);

          }
     }});
      
 
}
const userdata1 = localStorage.getItem('USER_data');
const userData = JSON.parse(userdata1);
  const userid = userData.id;
        const [updateTest, setupdateTest] = useState([])
        const [list, setlist] = useState([])

        const [updateTest1, setupdateTest1] = useState([])

    const fetchProducts = async () => {
        await axios.get('https://suhba.server.com.ly/back/api/Atbaks').then(({ data }) => {setData(data)
      //  setlist(data.orderItems) 
      }) 
             //  console.log('data',data)


        // Fetch orders for each user when the users state changes
       
        /*catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });*/ 
    } 
    const [inviteModal , setInviteModal] = useState(false);
    const [inviteModal1 , setInviteModal1] = useState(false);
    const [popupModal, setpopupModal] = useState(false);

    const show =(value)=>
    {
    setInviteModal1(value)
    
    }
    useEffect(() => {
        fetchProducts();
        },[]);
      
        function update(id) {
          console.log('id',id)

          data.map((item) => {
           if (id == item.AtbakID) {
               setupdateTest(item) 
               setupdateTest1(item.orderItems)
               console.log('ubdatedata',item)
               console.log('item.orderItems',item.orderItems)

               setInviteModal1(true)
            }
          })
        }
        const popupfunction = () => {
           setQun('');
           setpopupModal(false)
           setErrors('')
         }
  
        const handleDelete = async (id) => {
          
          swal({
            title: "هل متأكد من الحدف",
            text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
            .then(willDelete => {
              if (willDelete) {
      
                axios.delete(`https://suhba.server.com.ly/back/api/Atbaks/${id}`,)
      
                  .then(res => {
                    fetchProducts();
      
                    swal({
                      title: "تم",
                      text: "تم الحدف بنجاح",
                      icon: "success",
                      timer: 2000,
                      button: false
                    })
                    navigate('#')
      
      
                  });
      
              } else {
                swal("تم الغاء عملية الحدف");
              }
      
            });
 
        };
        //--         
        const [Qun, setQun] = useState('')
  
        const handleInputChange = (e) => {
          const input = e.target.value;
      
          // Check if the input is a number or an empty string
          if (/^\d*$/.test(input)) {
            data.map((item) => {
              if (AtbakID == item.AtbakID) {
                  if(input > item.QuantityInStock)
                    {
                      setErrors(`  القيمة المدخلة اكبر من الكمية المتبقية ,\n    عدد الاطباق المتبقي    ${item.QuantityInStock}`)
                    }else{
                  setQun(input);
                   setErrors('')

                    }
               }
             })



           
      
          } else {
            setErrors("هده الخانه لاتقبل حروف")
      
          }
        };        

        const handlesubmition = async () => {
        const error = await axios.post(`https://suhba.server.com.ly/back/api/Pay`, {
            amount: Qun,
            price: Qun*price1,
            UserID:userid,
            AtbakID: AtbakID,
          })
            .catch(function (error) {
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
            });
            fetchProducts();
            popupfunction();
        }

  return (
   <>
       
        <div className="container-fluid ">
          <div className="row">
          

                            

                    <div className="card">            
                <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">   

                 <div className="tbl-caption">
                            <h4 className="heading mb-0">أطباق التذوق</h4>
                            <div>
                            {(actionsForPage.includes('create') || actionsForPage.includes('edit')) && (     

                                 <Link to={"#"} className="btn btn-primary btn-sm ms-1" data-bs-toggle="offcanvas"
                                 onClick={() => invite.current.showInviteModal()}
                                >+ إضافة / تعديل الاطباق</Link> )}
                            
                            </div>
                        </div>  
                        </div>   </div> </div> 
            <div className="col-lg-12">    
              <div className="card mb-0 h-auto">
                <div className="card-body py-0 pe-0">
                  <div className="row gx-0">
                    
                         <div className="email-left-box dlab-scroll  pt-3 ps-0">


                         <div className="row mt-2">  



          <div className="row mt-2">    
          {
                                    data.length > 0 && (
                                        data.map((row,key)=>(
                                          <div className="col-lg-12 col-xl-6 col-xxl-4" key={row.AtbakID}>
                                          <div className="card">
                                            <div className="card-body">
                                              <div className="row m-b-30">
                                                <div className="col-md-5 col-xxl-12">
                                                  <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
                                                    <div className="new-arrivals-img-contnent">
                                                  <center>  <h4 >  <Link to="#">
                                                    <i class="fa-solid fa-basket-shopping" style={ { color:'green'}}
 onClick={actionsForPage.includes('create') ? () => listClickHandler1(row.AtbakID,row.TabakPrice)   
 : undefined}>
                                                            {' '} بيع {' '} 
                                                            </i>
                                                          </Link> </h4></center>
                                                       <img className="img-fluid" style={{ width: '300px', height: '150px' }} src={`https://suhba.server.com.ly/back/storage/ImageName/tabak.jpg`} />

                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-7 col-xxl-12">
                                                  <div className="new-arrival-content position-relative">
                                                  <div className="row">

                                                  <div className="col-md-5 col-xxl-12">
                                                  <h4>
                                                          <Link to="#">
                                                            <i className="fa-solid fa-pen-to-square" onClick={actionsForPage.includes('edit') ? () => update(row.AtbakID) : undefined}>
                                                              {row.TabakName}
                                                            </i>
                                                          </Link>
                                                    
                                                    <Link to="#" style={{float:'left'}}>
                                                            <i className="fas fa-trash-alt" onClick={actionsForPage.includes('delete') ? () => handleDelete(row.AtbakID) : undefined}>
                                                              
                                                            </i>
                                                          </Link></h4>
                                                  </div>
                                                  
                                                  </div>
                                                  <div className="row">

                                                   
                                                  <div className="col-md-6 ">
                                                  <p>
                                                      المتوفر حاليا:{" "}
                                                      <span className="item">
      {row.QuantityInStock}
      {row.QuantityInStock === 0 ? (
        <i className="fa-solid fa-circle-xmark text-danger me-2" />
      ) : (
        <i className="fa fa-check-circle text-success me-2" />
      )} 
    </span>
                                                    </p>
                                                  </div>
                                                  <div className="col-md-6 ">
                                                  <p>
                                                      الكمية: <span className="item">{row.Quantity}</span>{" "}
                                                    </p>
                                                    </div> 
                                                    
                                                   
                                                   
                                                   <div className="col-md-6 ">

                                                    <p>
                                                    الاصناف: <span className="item"></span> </p>
                                                   </div>
                                                   <div className="col-md-6 ">
                                                    <p>
                                                      السعر: <span className="item">{row.TabakPrice}</span>
                                                    </p>
                                                    </div>
                                                   <div className="col-md-12 ">

                                                    <p className="">
                                                    {row.orderItems.map(orderItem => (
                                <li style={{display: "inline-block",
                               /* Display list items horizontally */
                                marginright: "10px", /* Add some space between list items */
                                padding: "5px 10px", /* Add padding to each list item */
                               
                               }} key={orderItem.ItemID}>
                                    {orderItem.Name} 
                                </li>
                            ))}
                                                    </p>
                                                    
                                                    </div>
                                                    
                                                    </div>                                                                                               

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                   
                                        ))
                                    )
                                }
                 
                               

                        </div>



    </div>

                        </div>
                  
                 
                       
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal className="fade bd-example-modal-sm" size="sm" id="exampleModal1" show={popupModal} centered>
        <div className="modal-content">
          <div className="modal-body">
            <div className="col-sm-12 mb-3" >
              <label className="form-label">ادخل الكمية<span className="text-danger">*</span></label>

              <input type="text"
                className="form-control"
                value={Qun}

                //placeholder="ادخل قيم عددية صحيحه  فقط"
                required
                id="inputcity"
                name="city"
                onChange={handleInputChange} ></input>
            </div>
            {errors ? <div className=' alert alert-danger'>{errors}</div> : ""}

            <center>


              <button type="button" className="btn btn-primary light mx-1"  onClick={handlesubmition}>بيع</button>

              <button type="button" className="btn btn-danger light" onClick={popupfunction}>اغلاق</button>
            </center>
          </div>
        </div>

      </Modal>
        <InviteCustomer
                ref={invite}     
                parentFunction= {fetchProducts}
            /> 
             <InserdeModelUpdate
        updateTest={updateTest}
        updateTest1={updateTest1}
        show={show}
        ref={Update}
        inviteModal={inviteModal1}

        getAllData={fetchProducts}
      />
</>
  );
};

export default Atbak;

