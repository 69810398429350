
import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import swal from 'sweetalert';
import { ToastContainer, toast } from "react-toastify";
const InviteCustomer = forwardRef((props, ref) => {
  const [inviteModal, setInviteModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [listItem, setlistItem] = useState([])
  const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);
    const userid = userData.id;
    const navigate = useNavigate();
    useEffect(() => {
      const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          const form = e.target.form;
          const fields = Array.from(form.elements).filter(el => (
            (el.tagName === 'INPUT' && !el.disabled) ||
            (el.tagName === 'TEXTAREA' && !el.disabled) ||
            (el.tagName === 'SELECT' && !el.disabled)
          ));      const currentFieldIndex = fields.findIndex(el => el === document.activeElement);
          const nextFieldIndex = currentFieldIndex + 1;
          if (nextFieldIndex < fields.length) {
            fields[nextFieldIndex].focus();
          }
        }
      };
      
      document.addEventListener('keydown', handleEnterKey);
      
      return () => {
        document.removeEventListener('keydown', handleEnterKey);
      };
    }, []);
     
  const [tabak, setTabak] = useState({
    AtbakID: '',
    TabakName: '',
    Quantity: '',
    TabakPrice: '',
    TabakDate: '',
    OriginalPrice: '',
    QuantityInStock: '',
    UserID:userid
  });
  useEffect(() => {
    // Your effect logic here
    console.log('rops.updateTest', props.updateTest);
    setTabak(props.updateTest);
    setlistItem(props.updateTest1);
    setTotal(props.updateTest.OriginalPrice)
    getitems()

  }, [props.updateTest], [setTabak]); // Include forceUpdate in the dependency array

  // Function to force re-render






  const closeInviteModal = () => {

    setTabak('');
    setItemID('');

    props.getAllData();
    navigate('/Atbak')

    props.show(false)


  }

  const onSubmit = ev => {
    ev.preventDefault()
    console.log('tabaid', tabak.AtbakID)
    console.log('tabak', tabak)

    axios.put(`https://suhba.server.com.ly/back/api/Atbaks/${tabak.AtbakID}`, tabak)
      .then(() => {
        setTabak('')
        setTotal('')
        // props.parentFunction()

        props.getAllData()
        props.show(false)

      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });

  }
 
  const [ItemID, setItemID] = useState();
  const listClickHandler = (itemid) => {

    console.log(ItemID)
    FoodItem.map((item) => {
      if (itemid == item.ItemID) {
        console.log(item)
        setlistItem(listItem => [...listItem, { Name: item.Name, ItemID: itemid, ItemPrice: item.ItemPrice, UserID: userid },]);
        console.log(listItem)
        // const qunAsNumber = ;  Convert qun to a number, defaulting to 0 if conversion fails
        //let newTotal = parseFloat(total) + parseFloat(item.ItemPrice);
        setTotal(parseFloat(total) + parseFloat(item.ItemPrice))
        setTabak(prevTabak => ({
          ...prevTabak,
          OriginalPrice: parseFloat(total) + parseFloat(item.ItemPrice) // Replace 'newValue' with the value you want to push to OriginalPrice
        }));
        console.log("tabak in ",tabak)
        let newOrderItems = [];

        newOrderItems.push({ ItemID: ItemID, AtbakID: tabak.AtbakID, UserID:userid });

        //setTableData(newOrderItems);
        //console.log('newOrderItems',newOrderItems)
        axios.post('https://suhba.server.com.ly/back/api/tabak_items', { newOrderItems }).then(({ data }) => {
          setlistItem(data);

        //  setlist(data.orderItems) 
        }) 
        

        
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        });

      }
    })
    props.getAllData()


  }
  const getLasttabak = () => {

    let newOrderItems = [];

    // Populate the array with values
    newOrderItems.push({ ItemID: ItemID, AtbakID: tabak.AtbakID, UserID: userid });

    //setTableData(newOrderItems);
    //console.log('newOrderItems',newOrderItems)
    axios.post('https://suhba.server.com.ly/back/api/tabak_items', { newOrderItems }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
    //   closeInviteModal()
    // props.parentFunction()
    // setTabakPrice('');
    //  setTabakName('')
    //  setQuantity('')

  }
  const [FoodItem, setFoodItem] = useState([])
  const getitems = () => {
    axios.get('https://suhba.server.com.ly/back/api/getfood')
      .then(({ data }) => {
        setFoodItem(data.FoodItem)
      })
  }
  const handleDelete = async (e, id, index, ItemID) => {
    e.preventDefault();
    swal({
      title: "هل متأكد من الحدف",
      text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {

          axios.delete(`https://suhba.server.com.ly/back/api/tabak_items/${id}`)

            .then(res => {
              swal({
                title: "تم",
                text: "تم الحدف بنجاح",
                icon: "success",
                timer: 2000,
                button: false
              })

              // Remove the item at the specified index
              // const totalAA = updatedList.reduce((total, item) => total + parseInt(item.ItemPrice), 0);
              //  setTotal(totalAA);
              // Update the state with the new list


            });
          console.log('total', ItemID)

          const updatedList = [...listItem];
          updatedList.splice(index, 1);
          setlistItem(updatedList);
          FoodItem.map((item) => {
            if (ItemID == item.ItemID) {
              // setlistItem(listItem => [...listItem,{Name: item.Name ,ItemID:itemid ,ItemPrice:item.ItemPrice ,UserID:'1'}, ]);
              console.log('total', ItemID)
              // const qunAsNumber = ;  Convert qun to a number, defaulting to 0 if conversion fails
              //let newTotal = parseFloat(total) + parseFloat(item.ItemPrice);
              setTotal(parseFloat(total) - parseFloat(item.ItemPrice))

               setTabak(prevTabak => ({
                ...prevTabak,
                OriginalPrice: parseFloat(total) - parseFloat(item.ItemPrice) // Replace 'newValue' with the value you want to push to OriginalPrice
              }));


              //setTableData(newOrderItems);
              //console.log('newOrderItems',newOrderItems)


            }
          })
          props.getAllData()
          
        } else {
          swal("تم الغاء عملية الحدف");
        }

      });

  };



  const [total, setTotal] = useState()











  return (
    <>
      <Modal className="modal fade" id="exampleModal1" show={props.inviteModal} centered>

        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">تعديل طبق</h1>
            <button type="button" className="btn-close " style={{ marginLeft: 0 }} padding="" onClick={() => closeInviteModal()}></button>
          </div>
          <div className="modal-body py-2 px-4 ">
            <div className="row">
              <div className="col-xl-12">

                <div className="row">

                  <div className="col-xl-6">
                    <label className="form-label mt-3 ">اسم الطبق</label>
                    <input style={{ height: "2rem" }}
                      type="text"
                      pattern="[a-zA-Z\u0600-\u06FF\s]+"
                      title="الرجاء إدخال أحرف فقط، الفراغات، والحروف العربية"
                      required
                      id="inputcity"
                      name="city"
                      className="form-control  "
                      defaultValue={tabak.TabakName}
                      onChange={(ev) => setTabak({ ...tabak, TabakName: ev.target.value })} />

                  </div>
                  <div className="col-xl-6">
                    <label className="form-label mt-3 ">الكمية </label>

                    <input style={{ height: "2rem" }}
                      type="text"
                      pattern="[0-9]+"
                      title="هدا الحقل لا يقبل الا الارقام"
                      required
                      id="inputcity"
                      name="city"
                      className="form-control "
                      defaultValue={tabak.Quantity}
                      onChange={(ev) => setTabak(prevTabak => ({ 
                        ...prevTabak, 
                        Quantity: ev.target.value,
                        QuantityInStock: ev.target.value 
                    }))} />
                  </div>
                  <div className="col-xl-6 mb-1">
                    <label htmlFor="exampleFormControlInput99" className="form-label mt-3 " > تاريخ الطبق  <span className="text-danger">*</span></label>

                    <input
                      type="date"
                      className="form-control  " style={{ float: "right" }}
                      id="same-address" Name="TabakDate"
                      defaultValue={tabak.TabakDate}

                      onChange={(ev) => setTabak({ ...tabak, TabakDate: ev.target.value })} />


                  </div>
                  <div className="col-xl-6">
                    <label className="form-label mt-3">الصنف</label>

                    <select className="default-select form-control" name="ItemID"
                      id="inputcity"
                      required
                      onChange={(e) => { setItemID(e.target.value) }}>
                      <option data-display="Select">اختر من القائمة</option>
                      {
                        FoodItem.map((row) => (
                          <option key={row.ItemID} value={row.ItemID}>
                            {row.Name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-xl-6">
                    <label className="form-label mt-3 ">السعر </label>

                    <input style={{ height: "2rem" }}
                      type="text"

                      id="inputcity"
                      name="city"
                      className="form-control  "
                      defaultValue={tabak.TabakPrice}
                      onChange={(ev) => setTabak({ ...tabak, TabakPrice: ev.target.value })} />
                  </div>
                  <div className="col-xl-6">
                    <label className="form-label mt-3 "> السعر الاصلي  </label>

                    <input style={{ height: "2rem" }}
                      type="text"
                      disabled="false"


                      id="inputcity"
                      name="city"
                      className="form-control  "
                      value={total}
                    />
                  </div>
                  <div className="col-xl-12 pt-3">

                    <button className="btn btn-primary btn-lg btn-block"
                      onClick={() => listClickHandler(ItemID)}
                      style={{ padding: "0.5rem 5rem", fontsize: " 0.90rem !important", margintop: "10px" }}> اضف </button>



                  </div>
                </div>



                <div className="table-responsive active-projects task-table my-2 mx-2" style={{ height: "150px" }}>
                  <table className="table">
                    <thead>
                      <tr className="center" >

                        <th scope="col">الصنف</th>
                        <th scope="col">حدف</th>

                      </tr>
                    </thead>
                    <tbody>
                      {
                        listItem.length > 0 && (
                          listItem.map((row, key) => (
                            <tr key={key}>
                              <td>  {row.Name}   </td>
                              <td>  <Link onClick={(e) => handleDelete(e, row.TabakItemID, key, row.ItemID)}><i class="fas fa-trash-alt"



                              ></i></Link></td>
                            </tr>
                          ))
                        )
                      }

                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary " onClick={onSubmit}>حفظ التعديل</button>
            <button type="button" className="btn btn-danger " onClick={() => closeInviteModal()}>الغاء</button>


          </div>

        </div>

      </Modal>
    </>
  )
})
export default InviteCustomer;