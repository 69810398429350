import React, { Fragment } from "react";
import    { useState ,useEffect,useRef,forwardRef} from "react";
import qrcode from "../../src/images/qrcode.png";
import { SVGICON } from "../../src/jsx/constant/theme";
import { useLocation,useNavigate } from 'react-router-dom';
import logo from '../jsx/components/Reports/food.jpg';
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import InviteCustomer1 from './Sanadqabd';
import swal from 'sweetalert';

const Invoice = React.forwardRef((props, ref) => {
  const location = useLocation();const componentRef = useRef();
  const componentRef1 = useRef()
  const generatePDF = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Userdata",
    onAfterPrint: () => alert("Data saved in PDF")
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const formatDate = (date) => {
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
 
  };
  const generatePDF1 = useReactToPrint({
    content: () => componentRef1.current,
    documentTitle: "Userdata",
    onAfterPrint: () => alert("Data saved in PDF")
  });
  const searchParams = new URLSearchParams(location.search);
  const invite1 = useRef();

  const receivedDataArray = JSON.parse(searchParams.get('data')) || [];
  const receivedItemId = searchParams.get('id') || '';
  const DiscountAmount = searchParams.get('DiscountAmount') || '';
  const CustomerName = searchParams.get('name') || '';
  const lastdip = searchParams.get('TotalAmount') || '';
  const lastorder = searchParams.get('lastorder') || '';

  const [data3, setData3] = useState({});
  const [inviteModal1, setInviteModal1] = useState(false);

  const [orderItems, setOrderItems] = useState([]);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [Discount, setDiscount] = useState(0);
  const [Discount1, setDiscount1] = useState(0);
  const [report, setreport] = useState({})
  const userdata = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata);
    const userid = userData.id;
    const username = userData.name;

  let sum = 0
  useEffect(() => {
    const disableBack = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    // Disable the back button
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', disableBack);

    const newOrderItems = receivedDataArray.map((item, index) => ({
      ItemID: item.ItemID,
      OrderID: item.OrderID,
      ItemName:item.ItemName,
      TItemID: item.TItemID,
      UserID: userid,
      Quantity:item.Quantity,
     DiscountAmount: item.name  ,
      ItemPrice:item.ItemPrice,
total:item.total,
//TotalAmount:  item.TotalAmount,
  }));
    setOrderItems(newOrderItems);
  // cal();
    EditData55(lastorder)
   // const totalAmounts = orderItems.map(item => item.TotalAmount);
    //console.log("totalAmounts", totalAmounts)
  
        
  
    setTotalAmount(lastdip);
    console.log('TotalAmount',receivedDataArray.TotalAmount)

    setDiscount1(DiscountAmount);
    console.log(Discount1)
  }, [])
  console.log("newOrderItems", orderItems)
  
   // const intervalId = setInterval(() => {
    //   setOrderDate(new Date());
   //  }, 1000); // Update every 1000 milliseconds (1 second)
 
     // Cleanup function to clear the interval when the component unmounts
   //  return () => clearInterval(intervalId);
   const cal = () => {
    const totalAmounts = orderItems.map(item => item.TotalAmount);
    console.log("totalAmounts", totalAmounts)
  
        
  
    setTotalAmount(totalAmounts[0]);
    console.log(TotalAmount)

    setDiscount1(DiscountAmount);
    console.log(Discount1)

    //setDiscount((totalItemPrice-DiscountAmount)) ;
    //console.log(Discount)

  };
  const [report1, setReport1] = useState([]);

  const EditData556 = async (id) => {
     console.log('orderItems.OrderID',orderItems.OrderID);

   axios.get(`https://suhba.server.com.ly/back/api/getdipositisal/${lastorder}`)  
                     .then(({data}) => {
            //props.parentFunction()
            if (data.Receiptexpenses)
           { setReport1(data.Receiptexpenses)
            invite1.current.showInviteModal()
console.log('gggggg',data.Receiptexpenses)               
           }else{
            swal("لا يوجد إيصال استلام الضمان");
            props.show(false) 
           }
})
        .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
         
  }
  const EditData55 = async (id) => {
    console.log('idedit', id);

     const response = await axios.get(`https://suhba.server.com.ly/back/api/getdiposit/${lastorder}`);
    const depositData = response.data.Deposit;
       setData3(depositData)
    

  
      
    //setID(id); setInviteModal(true)
    //getfooditeming(e, id)
     
        const newObj = {
          amount: depositData.amount,
          CustomerName: CustomerName, 
          deposit_date: depositData.deposit_date, 
          reference_number: depositData.reference_number,
          deposits: depositData.deposits,
          reastAmount: (orderItems.TotalAmount - depositData.amount),
        };
   
    console.log('data3', newObj)

      setreport(newObj)
 
 
   // invite3.current.showInviteModal()
   // invite3.current.generatePDF();
  }
  const [inviteModal, setInviteModal] = useState(false);

  console.log(orderItems)
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mt-3">
             
              <div className="card-body" ref={componentRef}  dir="rtl">
              <div className="row">
              <div className="col-xl-12" > 
                <center><img src={logo} alt="Logo" className="logo" style={{ height: "100px", width: "150px", textAlign: "center" }} /></center>
                <div className="shop-info">
                  <h2 style={{ textAlign: "center" }}>صحبة طببة</h2>
                </div>
              </div>
            </div>
                 
                <div className="table-responsive">
                <table className="table table-bordered verticle-middle table-responsive-sm" style={{ marginBottom:'0px',borderCollapse: "unset", borderWidth: '1 px' }}>
                <tbody>
                <tr style={{width:'100%'}}>
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>رقم الايصال :{report.reference_number}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>الزبون  :{CustomerName}</td>
                  </tr>
                  <tr style={{width:'100%'}}>
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>التاريخ:{report.deposit_date}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>نوع الفاتورة : نهائية </td>
                  </tr></tbody>
                  </table>
                  <table className="table table-bordered verticle-middle table-responsive-sm" style={{marginBottom:'0px',marginTop:"1px"}}>
                    <thead>
                    
                      <tr>
                        <th className="center">#</th>
                        <th>الصنف</th>
                         <th className="right">سعر القطعة</th>
                        <th className="center">الكمية</th>
                        <th className="right">المجموع</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                      {receivedDataArray.map((item, ind)=>(
                                                    <tr className="center" key={ind}>
                                                        <td className="center">{ind}
                                                       </td>
                                                        <td className="left strong">{item.ItemName}  
                                                       </td>	
                                                        
                                                       <td className="right">{item.ItemPrice}</td>

                                                        <td className="center">{item.Quantity}</td>
                                                        <td lassName="right">{item.total}د</td>
                                                    </tr>  
                                                ))}
                       
                 
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-10 col-sm-5 mt-0 pt-0"> </div>
                  <div className="col-lg-2 col-sm-5 mt-0 pt-0 ">
                    <table className="table " style={{borderleft: '#E6E6E6 solid 1px',
  marginleft: "0px"}}>
                      <tbody>
                        <tr>
                          <td className="left">
                            <strong>الاجمالي</strong>
                          </td>
                          <td className="right">{TotalAmount}</td>
                        </tr>
                        <tr>
                          <td className="left">
                            <strong>التخفيض</strong>
                          </td>
                          <td className="right">{Discount1}</td>
                        </tr>
                        
                        <tr>
                          <td className="left">
                            <strong>الصافي</strong>
                          </td>
                          <td className="right">
                            <strong>{TotalAmount}</strong>
                            <br />
                           
                          </td>
                          
                        </tr>
                        <tr>
                          <td className="left">
                            <strong>العربون</strong>
                          </td>
                          <td className="right">
                            <strong>{report.amount}</strong>
                            <br />
                           
                          </td>
                          </tr>
                          <tr>
                          <td className="left">
                            <strong>الباقي</strong>
                          </td>
                          <td className="right">
                            <strong>{TotalAmount-data3.amount}</strong>
                            <br />
                           
                          </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row">

              <div className="col-xl-12  ">

<div className=" px-5" style={{float :'right'}}>إعد بواسطة :  {username}</div>  <div className=" px-5" style={{float :'left'}}>    بتاريخ {formatDate(currentDate)}</div><center><p>الختم</p></center>

    </div> </div>
         <div className="modal-footer">
            <button  className="btn btn-primary m-2" onClick={generatePDF}>طباعه الفاتورة</button>
                       <button className="btn btn-success m-2" onClick={() => setInviteModal(true)} >طباعه ايصال قبض العربون</button>
                       <Link
                                    className="btn btn-secondary btn-sm ms-1"
                                   to="#"       
                                  onClick={(e) => EditData556(lastorder)}
                                   >    
                                    طباعة إيصال إعادة الضمان
                                 </Link>
    <Link to={`/dashboard_1`} className="btn btn-primary">انهاء</Link>

          </div> </div>  </div>
          
        </div>
      </div>
      <Modal className="modal fade" id="exampleModal1" show={inviteModal} onHide={() => setInviteModal(false)}centered>
        <div className="modal-content">
          <div ref={componentRef1} className="modal-body" dir="rtl">
            <div className="row">
              <div className="col-xl-12">
                <center><img src={logo} alt="Logo" className="logo" style={{ height: "100px", width: "150px", textAlign: "center" }} /></center>
                <div className="shop-info">
                  <h2 style={{ textAlign: "center" }}>صحبة طببة</h2>
                  <p style={{ textAlign: "center" }}>ايصال قبض مبلغ مالي </p>
                </div>
              </div>
            </div>
            <div style={{ width: '100%' }} className="table-responsive">
              <table className="table table-bordered verticle-middle table-responsive-sm" style={{ borderCollapse: "unset", borderWidth: '1 px' }}>
                <tbody>
                  <tr>
                    <td style={{ boxShadow: '0 1px 1px 0 black' , border: '1px solid #ccc' }}>رقم الايصال :{report.reference_number}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}></td>
                  </tr>
                  <tr>
                    <td style={{ boxShadow: '0 1px 1px 0 black',border: '1px solid #ccc' }}>استلمت من: {report.CustomerName}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black' ,border: '1px solid #ccc'}}>بتاريخ: {report.deposit_date}</td>
                  </tr>
                  <tr>
                    <td style={{ boxShadow: '0 1px 1px 0 black' ,border: '1px solid #ccc'}}>مبلغ مالي بقيمة: {report.amount}</td>
                    <td style={{ boxShadow: '0 1px 1px 0 black' ,border: '1px solid #ccc',verticalAlign:'top'}} rowspan="2"> ودلك لي :{report.deposits} </td>
                  </tr>
                  <tr >
                    <td style={{ boxShadow: '0 1px 1px 0 black' ,border: '1px solid #ccc'}}>المبلغ المتبقي: {TotalAmount-data3.amount}</td>
                  </tr>
                  <tr >
                    <td style={{ boxShadow: '0 1px 1px 0 black'  ,border: '1px solid #ccc'}} colSpan="2">المبلغ بالحروف</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
              <button className="btn btn-success" onClick={generatePDF1}>PDF</button>
          </div>
        </div>
      </Modal>
      <InviteCustomer1
             ref={invite1}
        data={report}
        data2={report1}
      //  parentFunction={fetchProducts}
        inviteModal={inviteModal1}
        //show={show1}
         />
    </Fragment>
  );
});

export default Invoice;
