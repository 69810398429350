import React,{ useRef,useMemo } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import MOCK_DATA from '../jsx/components/table/FilteringTable/MOCK_DATA_2.json';
import { COLUMNS } from '../jsx/components/table/FilteringTable/ColumnAsnaf';
 import { Link,useNavigate } from "react-router-dom";
 import axios from "axios";
 import swal from 'sweetalert'; 
import '../jsx/components/table/FilteringTable/filtering.css';
import { Col, Row ,Dropdown} from 'react-bootstrap';
import InviteCustomer1 from '../jsx/constant/AddFoodItems';
import InviteCustomer from '../jsx/constant/FoodCategories';
import InviteCustomer2 from '../jsx/constant/EditFoodItems';
import InviteCustomer4 from '../jsx/constant/IngList';
import {useStateContext} from "../context/ContextProvider";

import  { useEffect,useState     } from 'react';
import InviteCustomer3 from '../jsx/constant/AddFoodIng';


export const CompanyExpenses = () => {
	const columns = useMemo( () => COLUMNS, [] )
	//const data = useMemo( () => MOCK_DATA, [] )
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])

  const [ID, setID] = useState(0)
  const [inviteModal1 , setInviteModal1] = useState(false);

  const [inviteModal , setInviteModal] = useState(false);
	const tableInstance = useTable({
		columns,
		data,	
		initialState : {pageIndex : 0}
	}, useFilters, useGlobalFilter, usePagination)
	
	const { 
		getTableProps, 
		getTableBodyProps, 
		headerGroups, 
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance
    const {globalFilter, pageIndex} = state	
    const navigate = useNavigate();

    const userdata = useRef();
    const invite1 = useRef();
    const invite = useRef();
    const invite3 = useRef();
    useEffect(() => {
      fetchProducts();
     
      
  }, [])
 

  const fetchProducts = async () => {
      await axios.get('https://suhba.server.com.ly/back/api/food_items').then(({ data }) => {setData(data)}) 
  }
  console.log(data);

    const deleteUser = async (e,id)=> {
        e.preventDefault();
        swal({
          title: "هل متأكد من الحدف",
          text: " في حال حدف الملف لايمكنك استعادة هدا الملف",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(willDelete => {
          if (willDelete) {
    
            axios.delete(`https://suhba.server.com.ly/back/api/expenses/${id}`)
            
              .then(res => {
                fetchProducts();
                swal({
                  title: "تم",
                  text: "تم الحدف بنجاح",
                  icon: "success",
                  timer: 2000,
                  button: false
                })
                navigate('#')
            
               
            });
        
          } else {
            swal("تم الغاء عملية الحدف");
          }
        
        });
    
    };
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    )
    const [foodIteming, setFoodIteming] = useState([])
    const getfooditeming = async (e,id)=> {
      data.map((item) => {
        if (id == item.ItemID) {
          setData2(item)
          setFoodIteming(item.ingredients)
               setInviteModal1(true)

        }
  
      })
    
    }
    const EditData = async (e,id)=> {
      console.log('idedit',id);
      data.map((item) => {
        if (id == item.ItemID) {
          setData2(item)
          
        setID(id);setInviteModal(true)
         
        }
  
      })
  
     
   
     // invite.current.showEmployeModal()
    }
    const show =(value)=>
{
  //setFoodIteming('')
  setInviteModal(value);
}
const show1 =(value)=>
{
  //setFoodIteming('')
  setInviteModal1(value);
}
const [selectedItem, setSelectedItem] = useState();
const handleSelect = (eventKey, pageIndex, itemIndexOnPage) => {
  const updatedData = [...data];
            updatedData[pageIndex].status = eventKey;
            setSelectedItem(eventKey) 
            console.log(eventKey)
            console.log(pageIndex)

                       setData(updatedData);console.log(pageIndex);

};
const [className, setClassName] = useState('');

const handleAction = async (id, field, value) => {
  console.log('id', id)
  const currentPageIndex = state.pageIndex; // Get the current page index

  let temp = data.map((data) => {
    if (id === data.ItemID) {

      return { ...data, [field]: value };
    }
    return data;

  }); 
  setData(temp)
  const formData = new FormData();
  formData.append('_method', 'PATCH')
  formData.append('ItemID', id);
  formData.append('status', value);
  formData.append('UserID', userid);

  const error = await axios.put(`https://suhba.server.com.ly/back/api/food_items/${id}`, {
    [field]: value,
  }) .then(res => {
    gotoPage(currentPageIndex); // Fetch products and maintain current page index
})
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
}
const {user,id, token, setUser, setToken, permissions} = useStateContext();
console.log("user",user);
console.log("id",id);
const userdata1 = localStorage.getItem('USER_data');
  const userData = JSON.parse(userdata1);

  // Access the specific item data
  const userid = userData.id;
  const userPermissionsJSON = localStorage.getItem('USER_PERMISSIONS2');

// Parse the JSON string back to an array
const userPermissions = JSON.parse(userPermissionsJSON);
// Function to search for permissions for a specific page
function getActionsForPage(pageName) {
  // Find the permissions object for the given page
  const pagePermissions = userPermissions.find(permission => permission.resource === pageName);
console.log('actionsForPage',pagePermissions); 

  // If permissions for the page are found, return the actions
  if (pagePermissions) {
      return pagePermissions.action;
  } else {
 navigate('/dashboard_1')

      return [];
  }
}

// Usage 
const pageName = 'Al-asnaf';
const actionsForPage = getActionsForPage(pageName);
    return(
<>
<div className='container-fluid'>
				<Row>
					<Col lg="12">
						<div className="card">

                        <div className="card-header">
                        <h4 className="heading ms-3">الاصناف</h4>
                        <div>
                        {actionsForPage.includes('create') && (

            <Link to={"#"} className='btn btn-primary btn-sm ' data-bs-toggle="offcanvas"
                onClick={() => invite1.current.showInviteModal()}
            >+ إضافة صنف</Link> )}{" "}
                                    {actionsForPage.includes('create') && (

            <Link to={"#"} className='btn btn-primary btn-sm' data-bs-toggle="offcanvas"
                onClick={() => invite.current.showInviteModal()}
            >+ إضافة مجموعة</Link>)}{" "}
                                                {actionsForPage.includes('create') && (

            <Link to={"#"} className='btn btn-primary btn-sm ' data-bs-toggle="offcanvas"
                onClick={() => invite3.current.showInviteModal()}
            >+ إضافة مكونات</Link>)}{" "}
                                                {actionsForPage.includes('export') && (

            <Link to={"/AsnafReport"} className='btn btn-secondary btn-sm' data-bs-toggle="offcanvas">
                تقارير الاصناف
            </Link>)}
        </div>
                        </div>  

                        
                        		 
								<div className="table-responsive">
									<table {...getTableProps()} className="table dataTable display table-hover">
										<thead>
										{headerGroups.map(headerGroup => (
												<tr {...headerGroup.getHeaderGroupProps()}>
												      {headerGroup.headers.map((column) => (
                        // Check if the column accessor is 'age' and render accordingly
                        column.id !== 'ItemID' ? (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                            {column.canFilter ? column.render('Filter') : null}
                          </th>
                        ) : null
                      ))}
												</tr>
										))}
										</thead> 
                    <tbody {...getTableBodyProps()} className="">
  {page.map((row,index) => {
    prepareRow(row);
    return (
      <tr {...row.getRowProps()} key={index}>
      {row.cells.map((cell) => {
          
          const cellProps = cell.getCellProps();
          const cellValue = cell.render('Cell');
          const isStatusColumn = cell.column.id === 'status';
          const isidColumn = cell.column.id === 'ItemID';

          // Check if the column is the 'status' column
          if (isStatusColumn) {
            const statusValue = row.original.status;

            // Add a class based on the status value
            const statusClass = statusValue === 'متوفر' ? 'Complete' : 'Pending';

            return (
              
              <td {...cellProps} >
              <Dropdown className="task-dropdown-2" >
                <div>

                  <Dropdown.Toggle style={{ width: "100px" }} className={statusClass} id="myElementId" as="div" >
                    {statusValue}  </Dropdown.Toggle>


                </div>


                <Dropdown.Menu className='task-drop-menu'>
                  <Dropdown.Item eventKey="متوفر" onClick={() => handleAction(row.original.ItemID, 'status', 'متوفر')}>متوفر
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="غير متوفر" onClick={() => handleAction(row.original.ItemID, 'status', 'غير متوفر')}>غير متوفر</Dropdown.Item>
            
                </Dropdown.Menu>
              </Dropdown>
            </td>
            );
          }

          if (!isidColumn) {
          return <td {...cellProps}>{cellValue}</td>;
   } })}
           <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        className="light sharp i-false" 
                      >
                           {svg1}
                       </Dropdown.Toggle>
                      <Dropdown.Menu dir="rtl" className="dropdown-menu-right">
                      {actionsForPage.includes('read') && (
                        <Dropdown.Item   onClick={(e) =>getfooditeming(e,row.original.ItemID)}>
                        <Link to className=""
                      
                        >عرض مكونات الصنف
                            </Link>
                            </Dropdown.Item>    )}
                            {actionsForPage.includes('edit') && (

                        <Dropdown.Item                                onClick={(e) =>EditData(e,row.original.ItemID)}
                        >                               
                                                        
                         <Link to className=""
                               >تعديل
                            </Link>
                        </Dropdown.Item> )}

                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
      </tr>
    );
  })}
</tbody>


                                                   
                                             
									</table>



									<div className="d-flex justify-content-between mx-2">
										<span>
											الصفحه{' '}
											<strong>
												{pageIndex + 1} من {pageOptions.length}
											</strong>{''}
										</span>
										<span className="table-index ">
											ادهب الى : {' '}
											<input type="number" 
												className="ml-2"
												defaultValue={pageIndex + 1} 
												onChange = {e => { 
													const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0 
													gotoPage(pageNumber)
												} } 
											/>
										</span>
									</div>
                                    <div className="text-center mb-3">  
                                        <div className="filter-pagination  mt-3">
                                            <button className="previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                {/* {'<'} */}
                                                <i className='fa-solid fa-angle-right' />
                                            </button>
                                            
                                            <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                السابق
                                            </button>
                                            <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                                التالي
                                            </button>
                                            <button className="next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                                {/* {'>'} */}
                                                <i className='fa-solid fa-angle-left' />
                                            </button>
                                        </div>
                                    </div>
								</div>
						 
						</div>
					</Col>
				</Row>
			</div>
            <InviteCustomer1
                ref={invite1}     
               parentFunction={fetchProducts}  
            /> 

<InviteCustomer2
data={data2}
                parentFunction={fetchProducts}  
                inviteModal={inviteModal}
                show={show} />
             <InviteCustomer
                   ref={invite} 
              //  data={data2}
              //  parentFunction={fetchProducts}  
              //  inviteModal={inviteModal}
              //  show={show}
            /> 
             <InviteCustomer3
                ref={invite3} data={data}
                />
<InviteCustomer4
data={foodIteming}
                 inviteModal1={inviteModal1}
                show={show1} />

</>


    );
}
export default CompanyExpenses;